<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <label>{{ q.answer[0].label }}</label>
            <b-form-input type="text" v-model="q.answer[0].data"/>
            <span class="mr-3">mg/dl</span>
            <b-form-checkbox v-model="q.answer[0].data" value="모름" unchecked-value="">모름</b-form-checkbox>
          </b-col>
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-input type="text" v-model="q.answer[1].data" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q9',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '공복혈당 검사 결과 및 날짜',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '공복혈당 검사 결과 및 날짜',
        answer: [
          {
            label: '결과',
            data: '',
          },
          {
            label: '공복혈당 검사일',
            data: '',
          },
        ],
      };
    }
  },
};
</script>